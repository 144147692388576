import React, { Suspense, useEffect, useState } from "react"
import AuthorDetailSection from "../components/authorProfile-sections/authorDetailSection"
import Authors from "../components/authorProfile-sections/authors"
import LatestBlogs from "../components/authorProfile-sections/latestBlogs"
import Subscribe from "../components/authorProfile-sections/subscribe"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"

const AboutUsPage = ({ pageContext }) => {
  const { seo } = pageContext?.item
  const [state, setState] = useState(false)

  let headSchema = []
  const bodySchema = seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const ContactSales = state
    ? React.lazy(() => import("../components/common/ContactSales"))
    : null

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        setState(true)
      }

      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <AuthorDetailSection data={pageContext?.item} />
      <LatestBlogs posts={pageContext?.allBlogs} author={pageContext?.item} />
      {/* <Subscribe /> */}
      <Authors authors={pageContext?.changeStrapiWriterSchema} />
      <Suspense fallback={<div>Loading...</div>}>
        {ContactSales && <ContactSales />}
      </Suspense>
    </MainLayout>
  )
}

export default AboutUsPage

export const Head = ({ pageContext }) => {
  const { seo } = pageContext?.item

  let headSchema = []
  const bodySchema = seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={seo?.metaTitle}
      description={seo?.metaDescription}
      schemas={headSchema}
      image={pageContext?.image && pageContext?.image?.localFile?.url}
    />
  )
}
