import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Newsletter from "./Newsletter"
import * as styles from "./subscribe.module.scss"

const Subscribe = () => {
  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className={styles.vectorShow}>
              <p className="main-heading-36 text-light ">
                Subscribe to our newsletter for trending news pieces and blog
                articles.
              </p>
            </div>
          </Col>
          <Col md={6}>
            {/* <Newsletter /> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Subscribe
