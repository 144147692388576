import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./avatarCard.module.scss"

const AvatarCard = ({ author, slug }) => {
  return (
    <div
      className={`align-items-center d-flex flex-column ${styles.cardAuthor}`}
    >
      {author.isCreatePage ? (
        <>
          <Link to={`/author/${slug}/`} className={styles.authors}>
            <GatsbyImage
              image={getImage(author?.image?.localFile)}
              className="rounded-circle"
              decoding="async"
              loading="lazy"
              placeholder="blurred"
              alt={author.name}
              style={{ height: "150px", width: "150px" }}
            />
            <div className={`px-3 ${styles.tp}`}>
              <p className={`mb-0 pt-2 ${styles.name}`}>{author?.name}</p>
              <p className={styles.title}>{author?.status}</p>
            </div>
          </Link>
        </>
      ) : (
        <>
          <GatsbyImage
            image={getImage(author?.image?.localFile)}
            className="rounded-circle"
            decoding="async"
            loading="lazy"
            placeholder="blurred"
            alt={author.name}
            style={{ height: "150px", width: "150px" }}
          />
          <div className={`px-3 ${styles.tp}`}>
            <p className={`mb-0 pt-2 ${styles.name}`}>{author?.name}</p>
            <p className={styles.title}>{author?.status}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default AvatarCard
